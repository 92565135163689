"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getQueryParam = function (param, url) {
    var regex = new RegExp("\\b(" + param + "=)\\b");
    var match = url.match(regex);
    if (!match) {
        return null;
    }
    var hasMoreValues = url.indexOf('&');
    var initial = match.index + match[0].length;
    if (hasMoreValues >= initial) {
        return url.substr(initial, hasMoreValues - initial);
    }
    var hasAnchor = url.lastIndexOf('#');
    return hasAnchor >= initial ? url.substr(initial, hasAnchor - initial) : url.substr(initial);
};
exports.default = getQueryParam;
